import styles from './Dashboard.module.css';
import { useContext, useEffect } from "react";
import { myContext } from "../../Context";
import { IUser } from "../../types/maintypes";

export default function Dashboardio() {
  const context = useContext(myContext) as IUser;
  let newUser = false;
  let dashboardName;

  // If user doesn't have beneficiary phone number prompt popup and blur background 
  useEffect(() => {
    if (!context.beneficiaryPhone) {
      document.getElementById('modal')?.classList.remove('d-none');
      document.getElementById('modalBackgroundId')?.classList.remove('d-none');
      document.getElementById('blurWrapper')?.classList.add('signup-modal-open');
    } else {
      document.getElementById('modal')?.classList.add('d-none');
      document.getElementById('modalBackgroundId')?.classList.add('d-none');
      document.getElementById('blurWrapper')?.classList.remove('signup-modal-open');
    }
  })
  if (context) {
      if (context.facebookImage) {
        dashboardName = <div><img alt="avatar" src={context.facebookImage}/><h1>{context.firstName}</h1></div>;
      } else {
        dashboardName = <span>{context.firstName}</span>;
      }
      if (!context.beneficiaryPhone) {
        newUser = true
      }
  } else {
    dashboardName = <span>Welcome Guest</span>;
  }
      return (
      <div className={styles.dashboardWrapper}>
        {newUser ? (
          <div className={styles.modal}></div>
        ) : (<div></div>)}
        <div className="row m-0">
          <div className={styles.dashboardMenu + " mt-2 col-3"}>
            <span className={styles.smallElogo}>e</span>Welcome {dashboardName}
            <ul className={"mt-4 " + styles.menuList}>
              <li>Messages</li>
              <li>Instructions</li>
              <li>Credentials</li>
            </ul>
          </div>
          <div className="col-9 mt-3 ps-4">
          Hi {dashboardName}
          </div>
        </div>
      </div>)
}