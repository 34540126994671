import styles from "./Login.module.css";
import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook-icon.svg';

export default function LoginPage() {
    const googleLogin = () => {
        window.open(`${process.env.REACT_APP_BACKEND_API}/auth/google`,"_self");
    }

    const facebookLogin = () => {
        window.open(`${process.env.REACT_APP_BACKEND_API}/auth/facebook`,"_self");
    }

    return (
        <div className={styles.loginPage}>
            <div className="row p-0 m-0">
                <div className="col-sm-6 col-4 mt-5 mb-5 pb-4 pt-4">
                    <div>
                        <h2 className={styles.loginTagline + " w-75"}>Some catch phrase tagline.</h2>
                    </div>
                    
                </div>
                <div className={styles.loginBox + " col-sm-6 col-8 mt-5 mb-5 pb-4 pt-4"}>
                    <h2 className={styles.loginHeader}>login.</h2>
                    <div className={styles.loginForm}>
                        <div className={styles.googleContainer} onClick={googleLogin}>
                            <GoogleIcon className={styles.googleSvg}/>
                            <div>Login With Google</div>
                        </div>
                        <div className={styles.facebookContainer} onClick={facebookLogin}>
                            <FacebookIcon className={styles.facebookSvg}/>
                            <div>Login With Facebook</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}