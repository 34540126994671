import Slider from "react-slick";
import styles from './Homepage.module.css';
import homeSlideSrcSetSml from "./Home_slider_1_mwghgu_c_scale,w_200.webp";
import homeSlideSrcSetMed from "./Home_slider_1_mwghgu_c_scale,w_893.webp";
import homeSlideSrcSetLrg from "./Home_slider_1_mwghgu_c_scale,w_1400.webp";

export default function Homepage() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 4000
    }

      // Homepage for user not logged in
        return (
          <div className={styles.homepageWrapper}>
             <Slider {...settings}>
            <div className="container-slider">
             <div className="row pt-2 p-0 m-0">
                <div className="col-sm-4 col-12 mt-auto mb-auto p-3">
                  <h1 className={styles.bannerText}>Write your own epilogue...</h1>
                  <p className={styles.bannerSubText}>The first portal to manage credentials, instructions and final messages that will automatically send to your selected loved ones at the time of your death.</p>
                </div>
                <div className="col-sm-8 col-12 p-0">
                  <img
                    className={styles.bannerImageCLS}
                    sizes="(max-width: 1400px) 100vw, 1400px"
                    srcSet={homeSlideSrcSetSml + ' 200w,' + homeSlideSrcSetMed + ' 893w,' + homeSlideSrcSetLrg + ' 1400w'}
                    src={homeSlideSrcSetLrg}
                    alt="cloud tech"/>
  
                    
                </div>
              </div>
              </div>
              <div className="container-slider">
                <div className="row pt-2 p-0 m-0">
                    <div className="col-sm-8 col-12 p-0">
                      <img
                        className={styles.bannerImageCLS}
                        sizes="(max-width: 1400px) 100vw, 1400px"
                        srcSet={homeSlideSrcSetSml + ' 200w,' + homeSlideSrcSetMed + ' 893w,' + homeSlideSrcSetLrg + ' 1400w'}
                        src={homeSlideSrcSetLrg}
                        alt="cloud tech"/>
                    </div>
                    <div className="col-sm-4 col-12 mt-auto mb-auto p-3">
                      <h1 className={styles.bannerText}>Placeholder Slider</h1>
                      <p className={styles.bannerSubText}> placeholder subtext slide </p>
                    </div>
                  </div>
              </div>
          </Slider>
          <div className={styles.bannerMissionStmt + " mt-2"}>
            <h2 className="w-75 mt-4 mb-4 m-auto">Our Mission</h2>
            <p className="w-75 m-auto mb-4">
                In January 2022 a friend of mine lost his life in a tragic accident. Any loss of a loved one seems impossible to deal with in the moment but it's made even more challenging when it's unexpected. This pushed me to consider my own morality and how exceptionally unprepared I am if I tragically lost my life. Unfortunately, people usually only start to consider drafting a will after they have children since you become responsible for another but we should also treat our own lives with the same importance. <br/><br/>
                This is the foundation that helped birth epilogged.io. A platform to provide instructions, send video, audio or written messages, and encrypt important credential information that will automatically send to your selected loved ones at the time of your death. We can do this by monitoring you account interaction, web scraping obituaries and sending regular texts to your chosen device at intervals of your choosing to ~ make sure you're not dead ~.  
                </p>
          </div>
          </div>
          
        );   
}