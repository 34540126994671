import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
import { AxiosResponse } from 'axios';

export const myContext = createContext({});
export default function Context(props: any) {

    const [isLoading, setLoading] = useState(true);
    const [userObject, setUserObject,] = useState<any>();
    console.log(window.location.href);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/getuser`, { withCredentials: true }).then((res: AxiosResponse) => {
            if (res.data) {
                setUserObject(res.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        })
    }, [])
    if (isLoading && (window.location.href !== "http://localhost:3000/" && window.location.href !== "https://epilogged.io/")) {
        return <div className="LoadingDiv text-center"><h2>Loading...</h2></div>;
    } else {
        return (
            <myContext.Provider value={userObject}>{props.children}</myContext.Provider>
        )
    }
}