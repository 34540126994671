import styles from './Modal.module.css';
// import axios from 'axios';
import { myContext } from '../../Context';
import { IUser } from '../../types/maintypes';
import { useContext } from 'react';

export default function NavBar() {
    const userObject = useContext(myContext) as IUser;
    //const logout = () => {
       // axios.get(`${process.env.REACT_APP_BACKEND_API}/auth/logout`, {
           // withCredentials: true 
      //  }).then(res => {
      //      if (res.data === "Done") {
       //         window.location.href = "./"
        //    }
      //  })
   // }
    if (userObject) {
        return (
            <div>
                <div id="modalBackgroundId" className={styles.modalBackground + " d-none"}></div>
                <div id="modal" className={styles.modal + " d-none"}>
                    <div className={styles.modalHeader + " text-center fw-bold mt-4 mb-2"}>Welcome to epilogged.io, {userObject.firstName}!</div>
                    <div className={styles.getStarted + " text-center w-75 m-auto"}>We just need some additional info before you can access your dashboard.</div>
                    <div className="m-auto w-75 mt-4">
                        <input className={styles.inputFields + " w-100 text-center"} type='text'/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<div></div>)
    }
   
}