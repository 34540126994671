import { BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Homepage from './Components/Hompage/Homepage';
import LoginPage from './Components/Login/LoginPage';
import NavBar from './Components/NavBar/NavBar';
import Modal from './Components/Modal/Modal';
import Footer from './Components/Footer/Footer';
import Dashboardio from './Components/Dashboard/Dashboard';

import './GlobalStyles.css';

function App() {
  return (
     <BrowserRouter>
     <Modal/>
      <div id="blurWrapper">
        <NavBar/>
        <Routes>
          <Route path='/' Component={Homepage} />
          <Route path='/login' Component={LoginPage} />
          <Route path='/dashboard' Component={Dashboardio} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer/>
      </div>
     </BrowserRouter>
  );
}

export default App;
