import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context from './Context';
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
<React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals