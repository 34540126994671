import styles from './Footer.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';


export default function Footer() {
    const [fname, setFname] = useState<any>();

    const handleChange = (e:any) => {
        setFname(e.target.value);
      };

    const updateUserTest = () => {
        axios.get("http://localhost:4000/updateUser", {
            withCredentials: true ,
            params: { beneficiaryPhone: fname }
        }).then(res => {
            if (res.data === "Done") {
                window.location.href = "./"
            }
        })
    }

    return (
        <div className={styles.footerWrapper}>
            <div className="d-none">
                <h1>Controlled Form</h1>
                <form>
                    <label>
                    First Name: <input type="text" value={fname} onChange={handleChange} />
                    </label>
                </form>
                <h5>First name: {fname}</h5>
            </div>
            <div className="row pt-5 m-0 mt-2">
                <div className="col-1 col-sm-2 border-left"/>
                <div className="col-sm-2 col-4">
                    <div className={styles.logoBackgroundMini + " mb-3"}><Link className="logo-mini" to='/'>epilogged.io</Link></div>
                    <p className={styles.pUnderLogo}>epilogged.io is the first digital will built to equip younger generations with a tool to manage their assets, instructions and messages to loved ones in the event of their passing.</p>
                </div>
                <div className="col-1 col-sm-2 border-right"/>
                <div className="col-sm-2 col-4">
                    <span onClick={updateUserTest}>Team</span><br/>
                    Contact<br/>
                    Privacy Policy<br/>
                    Terms and Conditions
                </div>
                <div className="col-sm-3 col-2 ps-sm-5 p-0">
                    Contact
                </div>
            </div>
        </div>
    )
}